<template>

<div class="file" :class="{'is-error': error}">

	<app-icon s="error" v-if="error" class="error" />

	<input type="file" ref="input" :accept="acceptedTypes" class="file-input" v-on:change="onFileSelected" />

	<div class="file-value" v-on:click="onClick">
		<template v-if="filename">{{ filename }} <small>Click to change file</small></template>
		<template v-if="!filename">{{ input.label }}<small>Click to select file</small></template>
		
	</div>
	
</div>

</template>

<script>

export default {

	props: ['input', 'value', 'error'],

	data: function() {

		return {
			filename: false
		}

	},

	computed: {

		acceptedTypes: function() {

			var types = []

			this.$_.each(this.input.extensions, function(type) {

				types.push(this.$constants.fileType[type])

			}.bind(this))

			return types.join(',')

		}

	},

	methods: {

		onClick: function() {

			this.$refs.input.click()

		},

		onFileSelected: function(e) {

			var file = e.target.files[0]

			var reader = new FileReader()

			reader.onloadend = function() {

				this.$emit('input', reader.result)

				this.filename = file.name

			}.bind(this)

			reader.readAsDataURL(file)
			
		}

	}

}

</script>

<style scoped>

.file {
	margin-bottom: 20px;
}

.file-input {
	display: none;
}

.file-value {
	background-color: #f5f5f5;
	padding: 0px 20px;
	height: 46px;
	color: #1A234C;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	letter-spacing: 1.4px;
	border-radius: 23px;
	width: 100%;
}

.file-value small {
	font-size: 11px;
	display: block;
	opacity: 0.75;
}

</style>
